import { merge } from 'lodash';
import { Sdk } from '../sdk';

export const users = (sdk: Sdk) => ({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateUserMetadata: async (id: string, metadata: any) => {
    const userQuery = await sdk.getUserById({ id });

    const currentMetadata = userQuery.users_by_pk?.metadata;

    const updatedMetadata = merge(currentMetadata, metadata);

    await sdk.updateUserById({ id, object: { metadata: updatedMetadata } });
  },
});
