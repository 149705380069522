/* eslint-disable @typescript-eslint/no-explicit-any */
import { Lambda } from 'aws-sdk';
import { get } from 'lodash';

export interface InvokeArgs {
  functionName: string;
  data?: any;
}

export const createLambdaProvider = (
  serviceName: string,
  serviceLocalURL: string
) => {
  const lambda = new Lambda({
    apiVersion: '2015-03-31',
    endpoint:
      process.env['APP_ENV'] == 'development' ? serviceLocalURL : undefined,
  });

  const invoke = async ({ functionName, data }: InvokeArgs) => {
    const invokeResponse = await lambda
      .invoke({
        FunctionName: `${serviceName || process.env['AWS_LAMBDA_SERVICE']}-${
          process.env['APP_ENV']
        }-${functionName}`,
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify(data),
      })
      .promise();

    return {
      data: JSON.parse(
        get(JSON.parse((invokeResponse.Payload as any).toString()), 'body')
      ),
      invokeResponse: invokeResponse,
    };
  };

  return { lambda, invoke };
};
