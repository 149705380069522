import { GraphQLClient } from 'graphql-request';
import { users } from '../sdk/providers/users.provider';
import { getSdk } from './../sdk/sdk';
import { HasuraService } from './hasura-service';

export const createHasura = () => {
  const config = {
    hasuraEndPoint: process.env['HASURA_GRAPHQL_ENDPOINT'] as string,
    hasuraAdminSecret: process.env['HASURA_GRAPHQL_ADMIN_SECRET'] as string,
  };

  const gqlEndPoint = `${config.hasuraEndPoint}/v1/graphql`;

  const client = new GraphQLClient(gqlEndPoint, {
    headers: { 'x-hasura-admin-secret': config.hasuraAdminSecret },
  });

  const sdk = getSdk(client);

  const hasura = new HasuraService(config.hasuraEndPoint, {
    'x-hasura-admin-secret': config.hasuraAdminSecret,
  });

  return {
    sdk: { ...sdk, ...users(sdk) },
    hasura,
  };
};

export type createHasuraReturn = ReturnType<typeof createHasura>;
