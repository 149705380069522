import { Enum_Store_Theme_Enum } from '@hoji/shared-backend';
export const themeStyles = {
  [Enum_Store_Theme_Enum.BlackWhitez]: '/black-whitez-theme.css',
  [Enum_Store_Theme_Enum.SoftyBlues]: '/softy-blues-theme.css',
  [Enum_Store_Theme_Enum.YellowVannilaz]: '/yellow-vannilaz-theme.css',
  [Enum_Store_Theme_Enum.PlanetzBlue]: '/planetz-blue-theme.css',
  [Enum_Store_Theme_Enum.DarqMagentaz]: '/darq-magentaz-theme.css',
  [Enum_Store_Theme_Enum.DarkenDarz]: '/darken-darz-theme.css',
  [Enum_Store_Theme_Enum.PurplePearl]: `/purple-pearl-theme.css`,
  [Enum_Store_Theme_Enum.AutumnLeaf]: `/autumn-leaf-theme.css`,
  [Enum_Store_Theme_Enum.AztecClay]: `/aztec-clay-theme.css`,
  [Enum_Store_Theme_Enum.BonJour]: `/bon-jour-theme.css`,
  [Enum_Store_Theme_Enum.BrightScampi]: `/bright-scampi-theme.css`,
  [Enum_Store_Theme_Enum.CafeAmber]: `/cafe-amber-theme.css`,
  [Enum_Store_Theme_Enum.CoralBrooks]: `/coral-brooks-theme.css`,
  [Enum_Store_Theme_Enum.FlushCararra]: `/flush-cararra-theme.css`,
  [Enum_Store_Theme_Enum.GrayAcapulco]: `/gray-acapulco-theme.css`,
  [Enum_Store_Theme_Enum.GrayTundora]: `/gray-tundora-theme.css`,
  [Enum_Store_Theme_Enum.MineShaft]: `/mine-shaft-theme.css`,
  [Enum_Store_Theme_Enum.PinkSpace]: `/pink-space-theme.css`,
  [Enum_Store_Theme_Enum.RogueBluewood]: `/rogue-bluewood-theme.css`,
  [Enum_Store_Theme_Enum.SalsaRed]: `/salsa-red-theme.css`,
  [Enum_Store_Theme_Enum.TerraContessa]: `/terra-contessa-theme.css`,
  [Enum_Store_Theme_Enum.DriftWood]: `/drift-wood-theme.css`,
};
